import React, { useEffect, useState } from 'react'

import SEO from '../../components/seo'
import { BackgroundWithGrid, Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { Nav } from '../../components/nav'
import { HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { LinkArrowIcon } from '../../components/icons'
import { ApplyForm } from '../../components/pages/CareersPage'
import { Button, Fact500Icon, FactBizIcon, FactLoyalIcon } from '../../components/pages/IndexPage'
import { FlutterWithText } from '../../components/icons/Technologies'
import { HeartIcon } from '../../components/icons/Shapes'
import { GatsbyImage } from 'gatsby-plugin-image'

const title = 'Flutter Framework'

const showcaseGalleryQuery = graphql`
    query {
        allFile(filter: { relativeDirectory: { eq: "expertise/flutter" } }) {
            nodes {
                name
                publicURL
                childImageSharp {
                    gatsbyImageData(
                        quality: 100,
                        layout: CONSTRAINED,
                        width: 166,
                        height: 334,
                        placeholder: BLURRED,
                        formats: [AUTO, WEBP]
                    )
                }
            }
        }
    }
`
const ShowcaseSection = (props) => {
  const [styleIndex, setStyleIndex] = useState(0)
  const data = useStaticQuery(showcaseGalleryQuery)

  const imageSource = data.allFile.nodes
  const weRideTodayScreenImage = imageSource.find((image) => image.name === 'screen-weridetoday')
  const greenBalkansScreenImage = imageSource.find((image) => image.name === 'screen-greenbalkans')
  const devStylerScreenImage = imageSource.find((image) => image.name === 'screen-devstyler')

  useEffect(() => {
    const updateActiveButton = () => {
      const hash = window.location.hash.replace('#', '')
      const indexMap = {
        weridetoday: 0,
        greenbalkans: 1,
        devstyler: 2,
      }
      const styleIndex = indexMap[hash] !== undefined ? indexMap[hash] : 0
      setStyleIndex(styleIndex)
    }

    updateActiveButton()
    window.addEventListener('hashchange', updateActiveButton)

    return () => {
      window.removeEventListener('hashchange', updateActiveButton)
    }
  }, [])

  return (
    <Section {...props}>
      <div className='w-full md:flex md:flex-row md:justify-center md:items-center'>
        <div className='py-2 my-2 text-2xl lg:text-3xl text-primary-3'>
          <h2>
            <Button href='#weridetoday' onClick={() => setStyleIndex(0)}
                    className={`text-primary-${styleIndex === 0 ? '1' : '3'}`}>
              WeRide.Today
            </Button>
          </h2>
        </div>
        <div className='py-2 my-2 text-2xl lg:text-3xl text-primary-3'>
          <h2>
            <Button href='#greenbalkans' onClick={() => setStyleIndex(1)}
                    className={`text-primary-${styleIndex === 1 ? '1' : '3'}`}>
              Green Balkans
            </Button>
          </h2>
        </div>
        {/*<div className='py-2 my-2 text-2xl lg:text-3xl text-primary-3'>*/}
        {/*  <h2>*/}
        {/*    <Button href='#devstyler' onClick={() => setStyleIndex(2)}*/}
        {/*            className={`text-primary-${styleIndex === 2 ? '1' : '3'}`}>*/}
        {/*      DevStyleR*/}
        {/*    </Button>*/}
        {/*  </h2>*/}
        {/*</div>*/}
      </div>

      <div className="my-8 py-8">
        {styleIndex === 0 && (
          <div className='grid gap-12 grid-cols-1 md:grid-cols-4'>
            <div className='md:row-span-2'>
              <GatsbyImage image={weRideTodayScreenImage.childImageSharp.gatsbyImageData} alt='WeRide.Today screenshot' />
            </div>
            <div className='md:col-span-3 flex flex-col justify-between'>
              <p className='font-normal'>
                Designed to empower daily commuters by providing real-time updates on route availability, weather
                conditions, and potential street hazards, while encouraging them to ride bikes more often in the city.
                Commuters can share Hints to one another and contribute valuable Suggestions to enhance their bicycle
                journeys, fostering a stronger connection with local authorities for improved riding experiences.
              </p>

              <div className='grid grid-cols-3 gap-4 my-8'>
                <div>
                  <FactBizIcon className="w-16"/>
                  <p className="text-4xl md:text-5xl">40%</p>
                  <p>savings on development costs</p>
                </div>
                <div>
                  <FactLoyalIcon className="w-16"/>
                  <p className="text-4xl md:text-5xl">70%</p>
                  <p>less overhead and maintenance</p>
                </div>
                <div>
                  <Fact500Icon className="w-16"/>
                  <p className="text-4xl md:text-5xl">45%</p>
                  <p>shorter delivery timeline</p>
                </div>
              </div>

              <Link className='flex items-baseline mt-4 h-6 text-xs text-primary-1' to='/showcase/weridetoday/'>
                <span className='uppercase font-bold tracking-normal'>Read more</span>
                <LinkArrowIcon className='inline ml-2 w-3 stroke-current' />
              </Link>
            </div>
          </div>
        )}
        {styleIndex === 1 && (
          <div className='grid gap-12 grid-cols-1 md:grid-cols-4'>
            <div className='md:row-span-2'>
              <GatsbyImage image={greenBalkansScreenImage.childImageSharp.gatsbyImageData} alt='Green Balkans screenshot' />
            </div>
            <div className='md:col-span-3 flex flex-col justify-between'>
              <p className='font-normal'>
                We believe in leveraging our technological expertise to make a positive impact on the world. Our
                collaboration with <a href="https://greenbalkans.org/en/" className="text-primary-1">Green Balkans</a>,
                Bulgaria's oldest wildlife conservation NGO, exemplifies our commitment to social responsibility — a
                core pillar of our company.
              </p>

              {/*<div className='grid grid-cols-3 gap-4 my-8'>*/}
              {/*  <div>*/}
              {/*    <FactBizIcon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">40%</p>*/}
              {/*    <p>savings on development costs</p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <FactLoyalIcon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">70%</p>*/}
              {/*    <p>less overhead and maintenance</p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <Fact500Icon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">45%</p>*/}
              {/*    <p>shorter delivery timeline</p>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <Link className='flex items-baseline mt-4 h-6 text-xs text-primary-1' to='/showcase/greenbalkans/'>
                <span className='uppercase font-bold tracking-normal'>Read more</span>
                <LinkArrowIcon className='inline ml-2 w-3 stroke-current' />
              </Link>
            </div>
          </div>
        )}
        {styleIndex === 2 && (
          <div className='grid gap-12 grid-cols-1 md:grid-cols-4'>
            <div className='md:row-span-2'>
              <GatsbyImage image={devStylerScreenImage.childImageSharp.gatsbyImageData} alt="DevStyleR screenshot" />
            </div>
            <div className='md:col-span-3 flex flex-col justify-between'>
              <p className='font-normal'>
                A multi-platform mobile application for the leading tech media in Bulgaria.{' '}
                <a href='https://devstyler.io/' className='text-primary-1'>DevStyleR</a> provides developers with the
                latest news, articles, and tutorials on software development, programming, and technology trends. The
                app features a user-friendly interface, personalized content, and a seamless reading experience.
              </p>

              {/*<div className='grid grid-cols-3 gap-4 my-8'>*/}
              {/*  <div>*/}
              {/*    <FactBizIcon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">40%</p>*/}
              {/*    <p>savings on development costs</p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <FactLoyalIcon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">70%</p>*/}
              {/*    <p>less overhead and maintenance</p>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <Fact500Icon className="w-16"/>*/}
              {/*    <p className="text-4xl md:text-5xl">45%</p>*/}
              {/*    <p>shorter delivery timeline</p>*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<Link className='flex items-baseline mt-4 h-6 text-xs text-primary-1' to='/showcase/devstyler/'>*/}
              {/*  <span className='uppercase font-bold tracking-normal'>Read more</span>*/}
              {/*  <LinkArrowIcon className='inline ml-2 w-3 stroke-current' />*/}
              {/*</Link>*/}
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

const ExpertiseFlutterPage = () => (
  <Layout className='bg-primary-1 text-primary-3'>
    <Analytics />
    <SEO title={title} pageUrl='/expertise/slutter/' type='article'
         description='Flutter is a core company technology we rely on!'
         cardImage='/img/tumbacardflutterlanding.png' />
    <MainWithMenu className='relative grid'>
      <Header className="z-20 pt-6 mb-72">
        <HeaderLink className='text-primary' />
        <div className='py-28 w-full flex justify-center items-center'>
          <FlutterWithText aria-label="Flutter Framework" className="w-72"/>
        </div>
      </Header>

      <BackgroundWithGrid image='feathers-hero' gridRow={1} />
      <Deco className='z-20 bg-primary-2' decoType={2} before after gridRow={2} />
      <Section className='z-20 pb-4' gridRow={2}>
        <h1 className='text-left text-primary-3 text-4xl lg:text-5xl'>
          Tumba <HeartIcon aria-label="loves" className="inline w-10 lg:w-12 text-primary-1 fill-current" /> Flutter
        </h1>
      </Section>

      <Deco className='bg-primary-2' decoType={2} after gridRow={3} />
      <Section className='z-20 pt-12 pb-12 grid gap-12 grid-cols-6 lg:grid-cols-12' gridRow={3}>
        <p className='col-span-6 text-lg sm:text-xl lg:text-2xl'>
          Expand your business reach with Flutter, a core Tumba multi-platform technology expertise.
        </p>
        <div className='col-span-6 sm:col-span-5 font-normal'>
          <p>
            <a href="https://flutter.dev/" className="text-primary-1">Flutter</a> is a powerful open-source UI toolkit
            developed by Google, designed for building natively compiled applications for mobile, web, and desktop
            platforms from a single codebase.
          </p>
          <p className='pt-6'>
            We possess deep understanding of Flutter’s capabilities and extensive mobile development
            experience. Whether you’re looking to launch a new product, modernize an existing
            application, or expand your digital presence across platforms, our Flutter expertise
            ensures your project’s success.
          </p>
        </div>
        <h1 className='col-span-5 lg:col-span-10 text-left text-primary-3 text-4xl lg:text-5xl'>
          Why choose Flutter?
        </h1>
        <div className='col-span-6'>
          <h2 className='col-span-5 text-2xl lg:text-3xl'>
            Business benefits
          </h2>
          <div>
            <h3 className='mt-10 text-primary-1 font-bold'>Cost Efficiency</h3>
            <p className='mt-4 font-normal'>
              Flutter slashes development and maintenance costs by enabling a single team to build your app for
              all platforms simultaneously. Its streamlined process delivers significant savings on resources and
              overhead.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Faster Market Entry</h3>
            <p className='mt-4 font-normal'>
              Flutter accelerates your product launch and update cycles dramatically. It enables rapid prototyping and
              instant feedback, significantly speeding up the entire development process.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Consistent Brand Experience</h3>
            <p className='mt-4 font-normal'>
              Flutter guarantees an unified user experience across devices that significantly enhances your brand’s
              perceived quality and professionalism, building stronger customer trust and loyalty.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Expanded Market Reach</h3>
            <p className='mt-4 font-normal'>
              Its multi-platform capability allows you to tap into a wider customer base, maximize your market presence
              and potential revenue streams without additional development effort.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Scalability and Future-Proofing</h3>
            <p className='mt-4 font-normal'>
              Flutter is open-source and backed by Google. It is continuously evolving, providing businesses with a
              robust framework that adapts to future technological advancements and keeps your product relevant and
              competitive.
            </p>
          </div>
        </div>
        <div className='col-span-6 sm:col-span-5'>
          <h2 id='product-projects' className='text-2xl lg:text-3xl'>
            Developer advantages
          </h2>
          <div>
            <h3 className='mt-10 text-primary-1 font-bold'>Open Source</h3>
            <p className='mt-4 font-normal'>
              Flutter is fully <a href='https://github.com/flutter/flutter' className='text-primary-1'>open-source</a>
              {' '} and developed publicly on GitHub. It has an engaged community and extensive package libraries.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Dart</h3>
            <p className='mt-4 font-normal'>
              Created by Google, <a href="https://dart.dev/" className="text-primary-1">Dart</a> offers a gentle
              learning curve while providing powerful features like strong typing and sound null safety that enhance
              code quality and prevent common runtime errors.<br/>
              Dart's ahead-of-time (AOT) compilation ensures high performance on mobile devices, while its just-in-time
              (JIT) compilation enables Flutter's fast development cycles.<br/>
              The language's versatility allows it to run natively on mobile and desktop, transpile to JavaScript
              for web applications, and compile to WebAssembly (Wasm) for near-native performance in web browsers.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Rich Pre-built Widget Library</h3>
            <p className='mt-4 font-normal'>
              Flutter's extensive set of customizable widgets enables rapid UI development through a declarative
              approach.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Comprehensive Developer Tooling</h3>
            <p className='mt-4 font-normal'>
              Flutter comes with a robust set of development tools, including powerful IDE integrations, Flutter
              DevTools for debugging and profiling, and a built-in testing framework.
            </p>
            <h3 className='mt-10 text-primary-1 font-bold'>Fast Development Cycles with Hot Reload</h3>
            <p className='mt-4 font-normal'>
              The hot reload feature allows developers to see code changes reflected instantly in the running app,
              maintaining the app’s state. This significantly speeds up the development process.
            </p>
          </div>
        </div>
      </Section>

      <BackgroundWithGrid image='feathers-hero' gridRow={4} />
      <Deco style={{ background: 'rgba(0, 0, 0, 0.4)' }} gridRow={4} className="z-0" />
      <Deco decoType={2} before inner after gridRow={4} className="z-20" />
      <Section className='z-20 mt-48 mb-48 h-72' gridRow={4}>
        <div className='inset-0 h-full w-full flex flex-col justify-center items-center'>
          <h1 className='text-3xl lg:text-5xl leading-tighter text-primary-3 text-shadow'>
            Build with Flutter
          </h1>
          <p className='font-normal text-center px-20 pt-10'>
            We’ve seen and evaluated many cross-platform frameworks in the past and now we can ensure that Flutter
            provides unparalleled efficiency in multi-platform development without compromising user or developer
            experience.
          </p>
        </div>
      </Section>

      <Deco className='bg-primary-2 z-20' decoType={2} gridRow={5} />
      <Section className='z-20 my-8 py-8' gridRow={5}>
        <ShowcaseSection />
      </Section>

      <Deco className='bg-primary' decoType={2} inner before gridRow={6} />
      <Section className='z-20 my-16 pt-16 pb-20' gridRow={6}>
        <h2 className='text-center mt-6 text-2xl md:text-3xl lg:text-4xl text-shadow text-primary'>
          Great brands use Flutter
        </h2>
        <p className="font-normal text-primary-2 text-center my-8 pl-20 pr-20">
          Flutter empowers you to outpace competitors, swiftly respond to market demands, and seize emerging
          opportunities.
        </p>
        <div className='text-primary-2 my-10 grid gap-12 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
          <div>Tumba Solutions</div>

          <div><a href="https://flutter.dev/showcase/google-pay" rel="nofollow">Google</a></div>

          {/*<div><a href="https://flutter.dev/showcase/toyota" rel="nofollow">Toyota</a></div>*/}
          {/*https://dilan.me/toyotas-flutter-based-in-vehicle-user-experience/*/}
          {/*https://medium.com/googleplaydev/seamless-multi-platform-app-development-with-flutter-ea0e8003b0f9*/}
          <div><a href="https://www.youtube.com/watch?v=zSbsIiluixw&t=1964s" rel="nofollow">Toyota</a></div>

          {/*<div><a href="https://flutter.dev/showcase/bmw" rel="nofollow">BMW</a></div>*/}
          <div><a href="https://www.press.bmwgroup.com/global/article/detail/T0328610EN/" rel="nofollow">BMW</a></div>

          <div><a href="https://flutter.dev/showcase/lucid-motors" rel="nofollow">Lucid Motors</a></div>

          {/*https://surf.dev/cases/flutter-banking-app/*/}
          <div>Société Générale</div>

          <div><a href="https://yo.bitrise.io/scaling-with-flutter-case-studies-with-tonal-and-signify-registration.html" rel="nofollow">Philips</a></div>
          <div><a href="https://webostv.developer.lge.com/news/2024-07-15-new-and-successful-experiment-of-webos-with-flutter" rel="nofollow">LG</a></div>
          <div>Bauhaus</div>
          <div><a href="https://medium.com/tide-engineering-team/tagged/flutter" rel="nofollow">Tide</a></div>

          {/*<div><a href="https://flutter.dev/showcase/nubank" rel="nofollow">NuBank</a></div>*/}
          {/*https://bitrise.io/blog/post/scaling-with-flutter-nubank*/}
          {/*<div><a href="https://medium.com/building-nubank/https-medium-com-freire-why-nubank-chose-flutter-61b80b568772" rel="nofollow">NuBank</a></div>*/}
          <div><a href="https://building.nubank.com.br/why-we-think-flutter-will-help-us-scale-mobile-development-at-nubank/" rel="nofollow">NuBank</a></div>

          <div><a href="https://flutter.dev/showcase/whirlpool" rel="nofollow">Whirlpool</a></div>
          <div><a href="https://tech-blog.sonos.com/posts/renovating-setup-with-flutter/" rel="nofollow">Sonos</a></div>

          {/*<div><a href="https://flutter.dev/showcase/wolt" rel="nofollow">Wolt</a></div>*/}
          {/*https://careers.wolt.com/en/blog/tech/scaling-the-wolt-merchant-app-to-serve-30x-more-users*/}
          <div><a href="https://www.youtube.com/watch?v=lZiT2p75U_I" rel="nofollow">Wolt</a></div>

          <div><a href="https://flutter.dev/showcase/sncf-connect" rel="nofollow">SNCF</a></div>
          <div><a href="https://flutter.dev/showcase/dow-jones" rel="nofollow">MarketWatch</a></div>

          {/*<div><a href="https://flutter.dev/showcase/ebay" rel="nofollow">eBay</a></div>*/}
          <div><a href='https://innovation.ebayinc.com/tech/product/ebay-motors-accelerating-with-fluttertm/' rel='nofollow'>eBay</a></div>

          {/*<div><a href="https://flutter.dev/showcase/rive" rel="nofollow">Rive</a></div>*/}
          <div><a href="https://rive.app/blog/why-we-chose-flutter-for-the-rive-gamekit" rel="nofollow">Rive</a></div>
        </div>
      </Section>

      <BackgroundWithGrid image="careers-apply" gridRow={7} />
      <Deco style={{ background: 'rgba(0, 0, 0, 0.5)' }} className="z-0" decoType={1} inner before gridRow={7} />
      <Section className='z-20 my-40 h-80' gridRow={7}>
        <div>
          <h2 className='text-3xl lg:text-4xl text-primary-1'>Let’s talk about Flutter</h2>
          <ApplyForm
            destination="contact+flutter"
            subject="Looking for Flutter expertise"
            messageHint="Tell us a bit more about the challenges you're facing"
          />
        </div>
      </Section>

      <Deco className='z-20 bg-primary-2' decoType={1} before gridRow={8} />
      <Nav gridRow={8} className='z-20 text-primary-2' />
    </MainWithMenu>
  </Layout>
)

export default ExpertiseFlutterPage
